/*
 * Settings Store
 * */
import { defineStore } from 'pinia';
import { ref, computed } from 'vue';
import { useAuthStore } from '@/store/AuthStore';
import { useRbacStore } from '@/store/RBACStore';
import { useCollection, useDocument } from 'vuefire';
import { collection, doc, getDoc, setDoc, updateDoc } from 'firebase/firestore';
import { fbDb } from '@/firebaseApp';
import { getFunctions, httpsCallable } from 'firebase/functions';

export const useSettingsStore = defineStore('settings', () => {
  const authStore = useAuthStore();
  const rbacStore = useRbacStore();

  const tenantId = computed(() => authStore.tenantId);
  const tenantSettingsDoc = computed(() => (tenantId.value ? doc(fbDb, `tenants/${authStore.tenantId}`) : null));

  // Tenant settings is a whole tenant document
  //const tenantSettings = useDocument(tenantSettingsDoc);

  const {
    // rename the Ref to something more meaningful
    data: tenantSettings,
    // is the subscription still pending?
    pending: tenantSettingsPending,
    // did the subscription fail?
    error: tenantSettingsError,
    // A promise that resolves or rejects when the initial state is loaded
    promise: tenantSettingsPromise,
  } = useDocument(tenantSettingsDoc);

  // Core capabilities
  const productEnabledBot = computed(() => tenantSettings.value?.productEnabledBot || false);
  const productEnabledQA = computed(() => (tenantSettings.value && 'productEnabledQA' in tenantSettings.value ? tenantSettings.value.productEnabledQA : true));
  const productEnabledCSat = computed(() => (tenantSettings.value && 'productEnabledCSat' in tenantSettings.value ? tenantSettings.value.productEnabledCSat : true));
  const productEnabledKB = computed(() => (tenantSettings.value && 'productEnabledKB' in tenantSettings.value ? tenantSettings.value.productEnabledKB : false));

  const slackIntegrationEnabled = computed(() => tenantSettings.value?.slackIntegrationEnabled);
  const zendeskIntegrationEnabled = computed(() => tenantSettings.value?.zendeskIntegrationEnabled);
  const zendeskIntegrationSubdomain = computed(() => tenantSettings.value?.zendeskSubdomain);
  const zendeskIntegrationClientId = computed(() => tenantSettings.value?.zendeskClientId || 'zdg-c7oai');
  const aircallIntegrationEnabled = computed(() => tenantSettings.value?.aircallIntegrationEnabled);
  const twilioIntegrationEnabled = computed(() => tenantSettings.value?.twilioIntegrationEnabled);
  const intercomIntegrationEnabled = computed(() => tenantSettings.value?.intercomIntegrationEnabled);

  const ingestJobId = computed(() => tenantSettings.value?.ingestJobId || null);
  const qaJobId = computed(() => tenantSettings.value?.qaJobId || null);

  // Feature toggle: Elastic index span - day (default) or month
  const elasticIndexSpan = computed(() => tenantSettings.value?.elasticIndexSpan || 'day');

  // TODO REFINE UI does not need to retrieve whole set of all settings for all integrations - including keys
  //  -> load integrationsSettings collection instead
  // Integrations settings are in integrations collection under tenant document
  const tenantIntegrations = useCollection(collection(fbDb, `tenants/${tenantId.value}/integrations`));

  // waits if settings are not yet loaded
  async function getElasticIndexSpan() {
    await tenantSettingsPromise.value;
    return elasticIndexSpan.value;
  }

  async function integrationsOAuthComplete(type, code, subdomain, redirectURI) {
    const functions = getFunctions();
    const integrationsOAuthCompleteFunction = httpsCallable(functions, 'integrationsOAuthComplete');
    let result = null;
    try {
      result = await integrationsOAuthCompleteFunction({
        type: type,
        code: code,
        subdomain: subdomain,
        redirectURI: redirectURI,
      });
    } catch (e) {
      const msg = `ERROR: Could not complete oAuth: ${e.message}`;
      console.log(msg);
      return {
        success: false,
        error: msg,
      };
    }

    console.log(`oAuth Complete Result: ${JSON.stringify(result)}`);
    return result.data;
  }

  async function getIntegrationSettings(integration) {
    const settingsDoc = await getDoc(doc(fbDb, `tenants/${authStore.tenantId}/integrations`, integration));
    if (settingsDoc.exists()) {
      return Object.assign({}, settingsDoc.data());
    }
    return null;
  }

  async function setIntegrationSettings(integration, settings) {
    const settingsDoc = doc(fbDb, `tenants/${authStore.tenantId}/integrations`, integration);
    try {
      await setDoc(settingsDoc, settings, { merge: true });
    } catch (e) {
      console.log(`setIntegrationSettings[${integration}]: Error writing settings: ${e.message}`);
      return false;
    }
    return true;
  }

  async function setIntegrationEnabled(integration, enabledValue) {
    const tenantSettingsDoc = doc(fbDb, `tenants`, authStore.tenantId);
    const enabledOpt = `${integration}IntegrationEnabled`;
    const updateData = {};
    updateData[enabledOpt] = enabledValue;

    try {
      await updateDoc(tenantSettingsDoc, updateData);
    } catch (e) {
      console.log(`setIntegrationEnabled[${integration}]: Error writing settings: ${e.message}`);
      return false;
    }
    return true;
  }

  return {
    tenantId,
    tenantSettings,
    tenantSettingsPromise,
    tenantIntegrations,
    productEnabledBot,
    productEnabledQA,
    productEnabledKB,
    productEnabledCSat,
    slackIntegrationEnabled,
    zendeskIntegrationEnabled,
    zendeskIntegrationSubdomain,
    zendeskIntegrationClientId,
    aircallIntegrationEnabled,
    twilioIntegrationEnabled,
    intercomIntegrationEnabled,
    ingestJobId,
    qaJobId,
    elasticIndexSpan,
    getElasticIndexSpan,
    getIntegrationSettings,
    setIntegrationSettings,
    setIntegrationEnabled,
    integrationsOAuthComplete,
  };
});
